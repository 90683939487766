import React from 'react';

import { Link } from 'react-router-dom';

import classes from './styles.module.scss';

export default function SearchResults({ searchResults }) {
  if (!searchResults.length)
    return (
      <div className={classes.nothingFound}>
        <p>Sorry, nothing found</p>
      </div>
    );

  return (
    <ul className={classes.SearchResults}>
      {searchResults?.map((result) => (
        <li key={result.id}>
          <Link to={`/projects/${result.id}`}>{result.title}</Link>
        </li>
      ))}
    </ul>
  );
}
