import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import classes from './styles.module.scss';

export default function HomePage() {
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();

  const search = () => {
    if (searchTerm.trim()) {
      navigate(`/search-results?query=${searchTerm}`);
    }
  };

  return (
    <div className={classes.HomePage}>
      <h1>Search Projects</h1>
      <div className={classes.searchBar}>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              search();
            }
          }}
        />
        <button type="button" onClick={search}>
          Search
        </button>
      </div>
    </div>
  );
}
