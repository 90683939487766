import React, { useState, useEffect, useRef } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import classes from './styles.module.scss';

const addProjectsInfo = (searchResults) => {
  const projectsInfo = searchResults.map(
    (result, index) => `Project ${index + 1}
    
Title: ${result.title}

Tech Stack: ${result.techStack}

Features: ${result.features || 'N/A'}
    
Description: ${result.description}

*******************
`
  );
  return projectsInfo.join('\n');
};

export default function Prompt({ searchResults, query }) {
  const [prompt, setPrompt] = useState('');

  const textareaRef = useRef();

  const handleCopy = () => {
    navigator.clipboard.writeText(textareaRef.current.value);
    toast.success('Copied to clipboard');
  };

  useEffect(() => {
    setPrompt(`Provide the answer to the request based on the info below.

Request: ${query}

Info: 

${addProjectsInfo(searchResults)}

    `);
  }, [query, searchResults]);

  return (
    <div className={classes.Prompt}>
      <h2>ChatGPT Prompt</h2>
      <textarea
        value={prompt}
        onChange={(event) => setPrompt(event.target.value)}
        ref={textareaRef}
      />
      <button className={classes.copyButton} type="button" onClick={handleCopy}>
        Copy
      </button>
      <ToastContainer />
    </div>
  );
}
