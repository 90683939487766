import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import SearchResults from '../../components/SearchResults';
import Spinner from '../../components/Spinner';
import Prompt from '../../components/Prompt';

import ProjectService from '../../services/ProjectService';
import classes from './styles.module.scss';

export default function SearchResultsPage() {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');

  const { data: searchResults, isFetching } = useQuery({
    queryKey: ['searchResults', query],
    queryFn: () => ProjectService.getProjects({ query }),
    enabled: !!query,
    staleTime: 1000 * 60 * 1, // 1 minute
    keepPreviousData: true,
  });

  return (
    <div className={classes.SearchResultsPage}>
      <h1>Search Results: {query}</h1>
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <SearchResults searchResults={searchResults} />
          {searchResults?.length ? (
            <Prompt searchResults={searchResults} query={query} />
          ) : null}
        </>
      )}
    </div>
  );
}
