import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import axios from 'axios';

import classes from './styles.module.scss';

export default function ProjectPage() {
  const [project, setProject] = useState();

  const { id } = useParams();

  // fetch project data from server using axios
  useEffect(() => {
    axios
      .get(`/projects/${id}`)
      .then((res) => setProject(res.data))
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <div className={classes.ProjectPage}>
      <h1>{project?.title}</h1>
      <h2>Tech Stack:</h2>
      <ul className={classes.techStackList}>
        {project?.techStack.split(',').map((tech) => (
          <li key={tech}>{tech}</li>
        ))}
      </ul>
      <h2>Description:</h2>
      <p>{project?.description}</p>
      <h2>Features:</h2>
      <ul className={classes.featuresList}>
        {project?.features?.split(',').map((feature) => (
          <li key={feature}>{feature}</li>
        ))}
      </ul>
    </div>
  );
}
