import axios from 'axios';

const ProjectService = {
  async getProjects({ query }) {
    const response = await axios.get(`/projects?search=${query}`);
    return response.data;
  },
};

export default ProjectService;
